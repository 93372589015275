<template>
  <div class="main-header">
    <div @click="sideBarToggle" class="menu-toggle">
      <div></div>
      <div></div>
      <div></div>
    </div>
    <div class="logo">
      <img src="@/assets/images/logo.png" alt />
    </div>

    <div class="d-flex align-items-center">
      <!-- Mega menu -->
<!--      <div-->
<!--        :class="{ show: isMegaMenuOpen }"-->
<!--        class="dropdown mega-menu d-none d-md-block"-->
<!--        v-on-clickaway="closeMegaMenu"-->
<!--      >-->
<!--        <a-->
<!--          href="#"-->
<!--          class="btn text-muted dropdown-toggle mr-3"-->
<!--          id="dropdownMegaMenuButton"-->
<!--          data-toggle="dropdown"-->
<!--          aria-haspopup="true"-->
<!--          aria-expanded="false"-->
<!--          @click="toggleMegaMenu"-->
<!--          >Mega Menu</a-->
<!--        >-->
<!--        <div-->
<!--          class="dropdown-menu text-left"-->
<!--          :class="{ show: isMegaMenuOpen }"-->
<!--          aria-labelledby="dropdownMenuButton"-->
<!--        >-->
<!--          <div class="row m-0">-->
<!--            <div class="col-md-4 p-4 text-left bg-img">-->
<!--              <h2 class="title">-->
<!--                Mega Menu-->
<!--                <br />Sidebar-->
<!--              </h2>-->
<!--              <p>-->
<!--                Lorem ipsum dolor sit amet consectetur, adipisicing elit.-->
<!--                Asperiores natus laboriosam fugit, consequatur.-->
<!--              </p>-->
<!--              <p class=" mb-30">-->
<!--                Lorem ipsum dolor sit amet consectetur, adipisicing elit.-->
<!--                Exercitationem odio amet eos dolore suscipit placeat.-->
<!--              </p>-->
<!--              <button class="btn btn-lg btn-rounded btn-outline-warning">-->
<!--                Learn More-->
<!--              </button>-->
<!--            </div>-->
<!--            <div class="col-md-4 p-4 text-left">-->
<!--              <p-->
<!--                class="text-primary text&#45;&#45;cap border-bottom-primary d-inline-block"-->
<!--              >-->
<!--                Features-->
<!--              </p>-->
<!--              <div class="menu-icon-grid w-auto p-0">-->
<!--                <a href="#"> <i class="i-Shop-4"></i> Home </a>-->
<!--                <a href="#"> <i class="i-Library"></i> UI Kits </a>-->
<!--                <a href="#"> <i class="i-Drop"></i> Apps </a>-->
<!--                <a href="#">-->
<!--                  <i class="i-File-Clipboard-File&#45;&#45;Text"></i> Forms-->
<!--                </a>-->
<!--                <a href="#"> <i class="i-Checked-User"></i> Sessions </a>-->
<!--                <a href="#"> <i class="i-Ambulance"></i> Support </a>-->
<!--              </div>-->
<!--            </div>-->
<!--            <div class="col-md-4 p-4 text-left">-->
<!--              <p-->
<!--                class="text-primary text&#45;&#45;cap border-bottom-primary d-inline-block"-->
<!--              >-->
<!--                Components-->
<!--              </p>-->
<!--              <ul class="links">-->
<!--                <li>-->
<!--                  <a href="accordion.html">Accordion</a>-->
<!--                </li>-->
<!--                <li>-->
<!--                  <a href="alerts.html">Alerts</a>-->
<!--                </li>-->
<!--                <li>-->
<!--                  <a href="buttons.html">Buttons</a>-->
<!--                </li>-->
<!--                <li>-->
<!--                  <a href="badges.html">Badges</a>-->
<!--                </li>-->
<!--                <li>-->
<!--                  <a href="carousel.html">Carousels</a>-->
<!--                </li>-->
<!--                <li>-->
<!--                  <a href="lists.html">Lists</a>-->
<!--                </li>-->
<!--                <li>-->
<!--                  <a href="popover.html">Popover</a>-->
<!--                </li>-->
<!--                <li>-->
<!--                  <a href="tables.html">Tables</a>-->
<!--                </li>-->
<!--                <li>-->
<!--                  <a href="datatables.html">Datatables</a>-->
<!--                </li>-->
<!--                <li>-->
<!--                  <a href="modals.html">Modals</a>-->
<!--                </li>-->
<!--                <li>-->
<!--                  <a href="nouislider.html">Sliders</a>-->
<!--                </li>-->
<!--                <li>-->
<!--                  <a href="tabs.html">Tabs</a>-->
<!--                </li>-->
<!--              </ul>-->
<!--            </div>-->
<!--          </div>-->
<!--        </div>-->
<!--      </div>-->
      <!-- / Mega menu -->
<!--      <div class="search-bar" @click="toggleSearch">-->
<!--        <input type="text" placeholder="Search" />-->
<!--        <i class="search-icon text-muted i-Magnifi-Glass1"></i>-->
<!--      </div>-->
    </div>

    <div style="margin: auto"></div>

    <div class="header-part-right">
      <!-- Full screen toggle -->
      <i
        class="i-Full-Screen header-icon d-none d-sm-inline-block"
        @click="handleFullScreen"
      ></i>
      <!-- <i class="i-Full-Screen header-icon d-none d-sm-inline-block" data-fullscreen></i> -->
      <!-- Grid menu Dropdown -->

<!--      <div class="dropdown">-->
<!--        <b-dropdown-->
<!--          id="dropdown"-->
<!--          text="Dropdown Button"-->
<!--          class="m-md-2"-->
<!--          toggle-class="text-decoration-none"-->
<!--          no-caret-->
<!--          variant="link"-->
<!--        >-->
<!--          <template slot="button-content">-->
<!--            <i-->
<!--              class="i-Safe-Box text-muted header-icon"-->
<!--              role="button"-->
<!--              id="dropdownMenuButton"-->
<!--              data-toggle="dropdown"-->
<!--              aria-haspopup="true"-->
<!--              aria-expanded="false"-->
<!--            ></i>-->
<!--          </template>-->
<!--          <div class="menu-icon-grid">-->
<!--            <a href="#"> <i class="i-Shop-4"></i> Home </a>-->
<!--            <a href="#"> <i class="i-Library"></i> UI Kits </a>-->
<!--            <a href="#"> <i class="i-Drop"></i> Apps </a>-->
<!--            <a href="#"> <i class="i-File-Clipboard-File&#45;&#45;Text"></i> Forms </a>-->
<!--            <a href="#"> <i class="i-Checked-User"></i> Sessions </a>-->
<!--            <a href="#"> <i class="i-Ambulance"></i> Support </a>-->
<!--          </div>-->
<!--        </b-dropdown>-->
<!--      </div>-->
<!--      &lt;!&ndash; Notificaiton &ndash;&gt;-->
<!--      <div class="dropdown">-->
<!--        <b-dropdown-->
<!--          id="dropdown-1"-->
<!--          text="Dropdown Button"-->
<!--          class="m-md-2  badge-top-container"-->
<!--          toggle-class="text-decoration-none"-->
<!--          no-caret-->
<!--          variant="link"-->
<!--        >-->
<!--          <template slot="button-content">-->
<!--            <span class="badge badge-primary">3</span>-->
<!--            <i class="i-Bell text-muted header-icon"></i>-->
<!--          </template>-->
<!--          &lt;!&ndash; Notification dropdown &ndash;&gt;-->
<!--          <vue-perfect-scrollbar-->
<!--            :settings="{ suppressScrollX: true, wheelPropagation: false }"-->
<!--            :class="{ open: getSideBarToggleProperties.isSideNavOpen }"-->
<!--            ref="myData"-->
<!--            class="dropdown-menu-right rtl-ps-none notification-dropdown  ps scroll"-->
<!--          >-->
<!--            &lt;!&ndash; <div class="dropdown-menu-right rtl-ps-none notification-dropdown"> &ndash;&gt;-->
<!--            <div class="dropdown-item d-flex">-->
<!--              <div class="notification-icon">-->
<!--                <i class="i-Speach-Bubble-6 text-primary mr-1"></i>-->
<!--              </div>-->
<!--              <div class="notification-details flex-grow-1">-->
<!--                <p class="m-0 d-flex align-items-center">-->
<!--                  <span>New message</span>-->
<!--                  &lt;!&ndash; <span class="badge badge-pill badge-primary ml-1 mr-1">new</span> &ndash;&gt;-->
<!--                  <span class="flex-grow-1"></span>-->
<!--                  <span class="text-small text-muted ml-auto">10 sec ago</span>-->
<!--                </p>-->
<!--                <p class="text-small text-muted m-0">-->
<!--                  James: Hey! are you busy?-->
<!--                </p>-->
<!--              </div>-->
<!--            </div>-->
<!--            <div class="dropdown-item d-flex">-->
<!--              <div class="notification-icon">-->
<!--                <i class="i-Receipt-3 text-success mr-1"></i>-->
<!--              </div>-->
<!--              <div class="notification-details flex-grow-1">-->
<!--                <p class="m-0 d-flex align-items-center">-->
<!--                  <span>New order received</span>-->
<!--                  &lt;!&ndash; <span class="badge badge-pill badge-success ml-1 mr-1">new</span> &ndash;&gt;-->
<!--                  <span class="flex-grow-1"></span>-->
<!--                  <span class="text-small text-muted ml-auto">2 hours ago</span>-->
<!--                </p>-->
<!--                <p class="text-small text-muted m-0">1 Headphone, 3 iPhone x</p>-->
<!--              </div>-->
<!--            </div>-->
<!--            <div class="dropdown-item d-flex">-->
<!--              <div class="notification-icon">-->
<!--                <i class="i-Empty-Box text-danger mr-1"></i>-->
<!--              </div>-->
<!--              <div class="notification-details flex-grow-1">-->
<!--                <p class="m-0 d-flex align-items-center">-->
<!--                  <span>Product out of stock</span>-->
<!--                  &lt;!&ndash; <span class="badge badge-pill badge-danger ml-1 mr-1">3</span> &ndash;&gt;-->
<!--                  <span class="flex-grow-1"></span>-->
<!--                  <span class="text-small text-muted ml-auto"-->
<!--                    >10 hours ago</span-->
<!--                  >-->
<!--                </p>-->
<!--                <p class="text-small text-muted m-0">-->
<!--                  Headphone E67, R98, XL90, Q77-->
<!--                </p>-->
<!--              </div>-->
<!--            </div>-->
<!--            <div class="dropdown-item d-flex">-->
<!--              <div class="notification-icon">-->
<!--                <i class="i-Data-Power text-success mr-1"></i>-->
<!--              </div>-->
<!--              <div class="notification-details flex-grow-1">-->
<!--                <p class="m-0 d-flex align-items-center">-->
<!--                  <span>Server Up!</span>-->
<!--                  &lt;!&ndash; <span class="badge badge-pill badge-success ml-1 mr-1">3</span> &ndash;&gt;-->
<!--                  <span class="flex-grow-1"></span>-->
<!--                  <span class="text-small text-muted ml-auto"-->
<!--                    >14 hours ago</span-->
<!--                  >-->
<!--                </p>-->
<!--                <p class="text-small text-muted m-0">-->
<!--                  Server rebooted successfully-->
<!--                </p>-->
<!--              </div>-->
<!--            </div>-->
<!--            &lt;!&ndash; </div> &ndash;&gt;-->
<!--          </vue-perfect-scrollbar>-->
<!--        </b-dropdown>-->
<!--      </div>-->
<!--      &lt;!&ndash; Notificaiton End &ndash;&gt;-->

      <!-- User avatar dropdown -->
      <div class="dropdown">
        <b-dropdown
          id="dropdown-1"
          text="Dropdown Button"
          class="m-md-2 user col align-self-end"
          toggle-class="text-decoration-none"
          no-caret
          variant="link"
        >
          <template slot="button-content">
            <img
              src="@/assets/images/user-placeholder-x2.png"
              id="userDropdown"
              alt
              data-toggle="dropdown"
              aria-haspopup="true"
              aria-expanded="false"
            />
          </template>

          <div class="dropdown-menu-right" aria-labelledby="userDropdown">
            <div class="dropdown-header">
              <i class="i-Lock-User mr-1"></i>
              {{userName}}
            </div>
<!--            <a class="dropdown-item">Account settings</a>-->
<!--            <a class="dropdown-item">Billing history</a>-->
            <a class="dropdown-item" href="#" @click.prevent="logoutUser"
              >Sign out</a
            >
          </div>
        </b-dropdown>
      </div>
    </div>
    <search-component
      :isSearchOpen.sync="isSearchOpen"
      @closeSearch="toggleSearch"
    ></search-component>
  </div>

  <!-- header top menu end -->
</template>
<script>
import Util from "@/gullkit/utils";
import Sidebar from "./Sidebar";
import searchComponent from "../common/search";
import { isMobile } from "mobile-device-detect";
import { mapGetters, mapActions } from "vuex";
import { mixin as clickaway } from "vue-clickaway";
import ApiClient from "@/ApiClient";

export default {
  mixins: [clickaway],
  components: {
    Sidebar,
    searchComponent
  },

  data() {
    return {
      isDisplay: true,

      isStyle: true,
      isSearchOpen: false,
      isMouseOnMegaMenu: true,
      isMegaMenuOpen: false
    };
  },
  mounted() {
    // document.addEventListener("click", this.closeMegaMenu);
  },
  computed: {
    ...mapGetters(["getSideBarToggleProperties"]),
    userName() {
      const userDataString = localStorage.getItem('user');
      if (userDataString !== '' && userDataString !== 'undefined' && userDataString !== undefined && typeof userDataString !== undefined && userDataString !== null) {
        const userData = JSON.parse(userDataString);
        return `${userData.firstName} ${userData.lastName}`;
      }

      return "";
    }
  },

  methods: {
    ...mapActions([
      "changeSecondarySidebarProperties",

      "changeSidebarProperties",
      "changeThemeMode",
      "signOut"
    ]),

    // megaMenuToggle() {
    //   this.isMegaMenuOpen = false;

    //   console.log("work");
    // },

    handleFullScreen() {
      Util.toggleFullScreen();
    },
    logoutUser() {
      ApiClient.get("/oauth/logout_url")
          .then(response => {
            const logoutUrl = response.data.logout;
            window.location.href = logoutUrl;
          })
          .catch(() => {
            this.$router.push({ name: "dashboard.v1" });
          });
      // this.signOut();
      //
      // this.$router.push("/app/sessions/signIn");
    },

    closeMegaMenu() {
      this.isMegaMenuOpen = false;
      // console.log(this.isMouseOnMegaMenu);
      // if (!this.isMouseOnMegaMenu) {
      //   this.isMegaMenuOpen = !this.isMegaMenuOpen;
      // }
    },
    toggleMegaMenu() {
      this.isMegaMenuOpen = !this.isMegaMenuOpen;
    },
    toggleSearch() {
      this.isSearchOpen = !this.isSearchOpen;
    },

    sideBarToggle(el) {
      if (
        this.getSideBarToggleProperties.isSideNavOpen &&
        this.getSideBarToggleProperties.isSecondarySideNavOpen &&
        isMobile
      ) {
        this.changeSidebarProperties();
        this.changeSecondarySidebarProperties();
      } else if (
        this.getSideBarToggleProperties.isSideNavOpen &&
        this.getSideBarToggleProperties.isSecondarySideNavOpen
      ) {
        this.changeSecondarySidebarProperties();
      } else if (this.getSideBarToggleProperties.isSideNavOpen) {
        this.changeSidebarProperties();
      } else if (
        !this.getSideBarToggleProperties.isSideNavOpen &&
        !this.getSideBarToggleProperties.isSecondarySideNavOpen &&
        !this.getSideBarToggleProperties.isActiveSecondarySideNav
      ) {
        this.changeSidebarProperties();
      } else if (
        !this.getSideBarToggleProperties.isSideNavOpen &&
        !this.getSideBarToggleProperties.isSecondarySideNavOpen
      ) {
        // console.log("4");

        this.changeSidebarProperties();
        this.changeSecondarySidebarProperties();
        // console.log("4");
      }
    }
  }
};
</script>

<style scoped>
.layout-sidebar-large .main-header .logo {
  height: 80%;
  width: unset;
}
.layout-sidebar-large .main-header .logo img {
  height: 100%;
  width: unset;
}
</style>

